import React, { useState, useEffect } from "react";

import { Stack, Pagination, TextField } from "@mui/material";
import * as XLSX from "xlsx";

import {
  Col,
  Row,
  Card,
  Form,
  Button,

} from "@themesberg/react-bootstrap";

import "react-time-picker/dist/TimePicker.css";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSignOutAlt,
  faTrash,

  faDownload,
  faEye,

} from "@fortawesome/free-solid-svg-icons";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import DownloadExcelButton from "./downloadExel";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import PaginationButton from "rsuite/esm/Pagination/PaginationButton";




export const User = () => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  console.log("🚀 ~ User ~ selectedUserIds:", selectedUsers)
  const [category, setCategory] = React.useState("");
  // const [searchQuery, setSearchQuery] = useState(data);
  const handlePerPageChange = (selectedOption) => {
    setPerPage(selectedOption.value);
    // Call your function to fetch data with the new pagination limit
    // For example: getMyUserData(searchQuery, currentPageNumber, selectedOption.value);
  };
 

  const [perPage, setPerPage] = useState(10); // Set an initial value for the dropdown

  const [records, setRecords] = useState();
  const [jsonData, setJsonData] = useState([]);
  // const [jsonData, setJsonData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);

  const [time, setTime] = useState("00:00:00");
  const [expirationModal, setExpirationModal] = useState(false);
  const [myData, setMyData] = useState({
    category_name: "",
    description: "",
    // exam_name: "",
    // marks_awarded: "",
    // marks_deducted: "",
    // time_duration: "",
    // negative_marking: false,
  });
  const [isError, setIsError] = useState("");

  const [editData, setEditData] = useState({});

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalpage, setTotalPage] = useState(0);

  const [checkedUsers, setCheckedUsers] = useState([]);
  const history = useHistory();
  const expirationData = { day: "" };
  const [isLoadingData, setIsLoadingData] = useState(true);
  const navigateDetails = (userId) => {
    history.push(`/userDetails?id=${userId}`);
  };

  const options = ["NEET SS", "INI SS", "MRCS", "NEET SS", "INI SS"];

  const SigninSchema = Yup.object().shape({
    category_name: Yup.string().required("Category name is Required"),
    description: Yup.string().required("description is Required"),
    // marks_awarded: Yup.string().required("Marks Awarded is Required"),
    // exam_name: Yup.string().required("exam name is Required"),
  });

  const [datatable, setDatatable] = useState({
    columns: [
      {
        lebal: "Select",
        field: "select",
      },
      {
        label: "No",
        field: "No",
        width: 270,
      },  
      {
        label: "Full name",
        field: "fullname",
        width: 100,
      },
      {
        label: "Phone",
        field: "phone",
        width: 100,
      },
      {
        label: "Email",
        field: "email",
        width: 100,
      },
      {
        label: "date of birth",
        field: "date_of_birth",
        width: 100,
      },
      {
        label: "Exams",
        field: "exams",
        width: 100,
      },
      {
        label: "preparing_for",
        field: "preparing_for",
        width: 100,
      },
      {
        label: "Subscribed?",
        field: "isSubscribed",
        width: 100,
      },
      {
        label: "Subscription Plans",
        field: "planeNames",
        width: 100,
      },
      
      {
        label: "Action",
        field: "actions",
        width: 100,
      },
      {
        label: "Activity",
        field: "activity",
        width: 100,
      },
    ],
  });


  const columnOrder = [ "fullname", "phone","email","date_of_birth","exams","preparing_for","isSubscribed","planeNames"];
  const logoutUser = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout this user",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem("token");
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/user/logOutUserByAdmin`,
            {
              user_id: id,
            },
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refetch the subcategory data after successful update.
          // getMySubCategoryData();
          // onAddCloseModal(); // Close the modal after successful update.
          Swal.fire({
            position: "center",
            icon: "success",
            title: "User logged out Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          // getSubscription();
        } catch (error) {
          console.error(error.response); // Handle error responses
        }
      }
    });
  };
  const handleCategoryChange = (category) => {
    setCategory(category);
    console.log(category);
  };
  const [users, setUsers] = useState([]);

  // const getMyUserData = async (searchQuery, pageNumber = 1, limitPage = 10) => {
  //   console.log(searchQuery, "searchQuery", pageNumber, "pageNumber");
  //   const token = localStorage.getItem("token");
  //   setIsLoadingData(true);
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/user/getAll` +
  //         "?" +
  //         `${searchQuery ? "searchQuery=" + searchQuery + "&" : ""}` +
  //         `${pageNumber ? "page=" + pageNumber + "&" : ""}` +
  //         `${limitPage ? "limit=" + limitPage + "&" : ""}`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );

  //     console.log("res.data -------totalpage ", res.data.totalpage);
  //     setTotalPage(res.data.totalpage);
  //     // setTotalPage(Math.ceil(res.data.totalRecords / limitPage));
  //     const usersWithCheckState = res.data.data.map((user) => ({
  //       ...user,
  //       isChecked: false,
  //     }));

  //     setUsers(usersWithCheckState);
  //     console.log("res.data -------currentPageNumber ", pageNumber);
  //     const data = usersWithCheckState.map((item, index) => ({
  //       No: index + 1 + (pageNumber - 1) * 10,
  //       fullname: item.fullname,
  //       phone: item.phone,
  //       email: item.email,
  //       preparing_for: item.preparing_for,
  //       date_of_birth: item.date_of_birth,
  //       isSubscribed: item.isSubscribed ? "Yes" : "No",
  //       planeNames: item.planeNames,
  //       isChecked: false,
  //       exams:
  //         item.exams.length > 1
  //           ? item.exams.map((exams, examsIndex) => (
  //               <div key={examsIndex}>{`${examsIndex + 1}. ${exams}`}</div>
  //             ))
  //           : item.exams[0],

  //       actions: (
  //         <div>
  //           <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />
  //           <FontAwesomeIcon
  //             icon={faTrash}
  //             className="mx-3"
  //             onClick={() => handleDelete(item._id)}
  //           />
  //           <FontAwesomeIcon
  //             style={{ marginRight: "1rem" }}
  //             icon={faEye}
  //             onClick={() => navigateDetails(item._id)}
  //           />
  //           <FontAwesomeIcon
  //             icon={faSignOutAlt}
  //             onClick={() => logoutUser(item._id)}
  //           />
  //         </div>
  //       ),

  //       activity: (
  //         <label className="switch-container">
  //           <span className="switch-label"></span>
  //           <div className="switch">
  //             <input
  //               type="checkbox"
  //               checked={item.isActive}
  //               onChange={(e) => handleToggle(e, item._id)}
  //             />
  //             <span className="slider"></span>
  //           </div>
  //         </label>
  //       ),
  //       select: (
  //         <div key={item._id}>
  //         <input
  //           value={selectedUsers.id}
  //           type="checkbox"
  //           onChange={(e) => handleCheckboxChange(e, item)}
  //         />
  //         {item.name}
  //       </div>
  //       ),
  //     }));

  //     // setJsonData(res.data.data);

  //     setDatatable((prevState) => ({
  //       ...prevState,
  //       rows: data,
  //     }));
  //     setIsLoadingData(false);
  //   } catch (error) {
  //     console.log(error, "error");
  //     if (error.response.status === 401) {
  //       history.push("/sign-in");
  //     }
  //     setIsError(error.response);
  //     setIsLoadingData(false);
  //   }
  // };
  const getMyUserData = async (searchQuery, pageNumber = 1, limitPage = 10) => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true); // Set loading to true when fetching data
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user/getAll` +
          "?" +
          `${searchQuery ? "searchQuery=" + searchQuery + "&" : ""}` +
          `${pageNumber ? "page=" + pageNumber + "&" : ""}` +
          `${limitPage ? "limit=" + limitPage + "&" : ""}`,
        {
          headers: { Authorization: token },
        }
      );
  
      setTotalPage(res.data.totalpage);
      const usersWithCheckState = res.data.data.map((user) => ({
        ...user,
        isChecked: false,
      }));
  
      setUsers(usersWithCheckState);
      const data = usersWithCheckState.map((item, index) => ({
        No: index + 1 + (pageNumber - 1) * 10,
        fullname: item.fullname,
        phone: item.phone,
        email: item.email,
        preparing_for: item.preparing_for,
        date_of_birth: item.date_of_birth,
        isSubscribed: item.isSubscribed ? "Yes" : "No",
        planeNames: item.planeNames,
        isChecked: false,
        exams:
          item.exams.length > 1
            ? item.exams.map((exam, examsIndex) => (
                <div key={examsIndex}>{`${examsIndex + 1}. ${exam}`}</div>
              ))
            : item.exams[0],
  
        actions: (
          <div>
            <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />
            <FontAwesomeIcon
              icon={faTrash}
              className="mx-3"
              onClick={() => handleDelete(item._id)}
            />
            <FontAwesomeIcon
              style={{ marginRight: "1rem" }}
              icon={faEye}
              onClick={() => navigateDetails(item._id)}
            />
            <FontAwesomeIcon
              icon={faSignOutAlt}
              onClick={() => logoutUser(item._id)}
            />
          </div>
        ),
  
        activity: (
          <label className="switch-container">
            <span className="switch-label"></span>
            <div className="switch">
              <input
                type="checkbox"
                checked={item.isActive}
                onChange={(e) => handleToggle(e, item._id)}
              />
              <span className="slider"></span>
            </div>
          </label>
        ),
        select: (
          <div key={item._id}>
            <input
              value={selectedUsers.id}
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, item)}
            />
            {item.name}
          </div>
        ),
      }));
  
      setDatatable((prevState) => ({
        ...prevState,
        rows: data,
      }));
      setIsLoadingData(false); // Set loading to false after fetching data
    } catch (error) {
      console.log(error, "error");
      if (error.response && error.response.status === 401) {
        history.push("/sign-in");
      }
      setIsError(error.response);
      setIsLoadingData(false); // Set loading to false if an error occurs
    }
  };
  

  const getAllMyUserData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    console.log(setIsLoadingData,"setIsLoadingDatasetIsLoadingData");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user/v2/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setJsonData(res.data);
      // setSelectedUsers(res.data);
      console.log(res.data,"resdata");
      setIsLoadingData(false);
    } catch (error) {
      console.log(error, "error");
      if (error.response.status === 401) {
        history.push("/sign-in");
      }
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  const getMyUserData1 = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data);

      const data = res.data?.data.map((item, index) => ({
        id: index + 1,
        fullname: item.fullname,
        phone: item.phone,
        email: item.email,
        date_of_birth: item.date_of_birth,
        isSubscribed: item.isSubscribed ? "Yes" : "No",
        planeNames: item.planeNames,
        isChecked: false,
        exams:
          item.exams.length > 1
            ? item.exams.map((exams, examsIndex) => (
                <div key={examsIndex}>{`${examsIndex + 1}. ${exams}`}</div>
              ))
            : item.exams[0],

        actions: (
          <div>
            <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />
            <FontAwesomeIcon
              icon={faTrash}
              className="mx-3"
              onClick={() => handleDelete(item._id)}
            />
            <FontAwesomeIcon
              style={{ marginRight: "1rem" }}
              icon={faEye}
              onClick={() => navigateDetails(item._id)}
            />
            <FontAwesomeIcon
              icon={faSignOutAlt}
              onClick={() => logoutUser(item._id)}
            />
          </div>
        ),

        activity: (
          <label className="switch-container">
            <span className="switch-label"></span>
            <div className="switch">  
              <input
                type="checkbox"
                checked={item.isActive}
                onChange={(e) => handleToggle(e, item._id)}
              />
              <span className="slider"></span>
            </div>
          </label>
        ),
        // select: (
        //   <div>
        //     <input
        //       type="checkbox"
        //       onChange={(e) => handleCheckboxChange(e, item._id)}
        //     />
        //   </div>
        // ),
      }));
      setIsLoadingData(false);
      // setJsonData(data);
      // setDatatable((prevState) => ({
      //   ...prevState,
      //   rows: data,
      // }));
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  // const getMyUserData = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/Category/getAll`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     // setMyCategoryData(res.data);
  //     setMyCategoryData(
  //       res.data.map((i, index) => ({ ...i, recordNo: index + 1 }))
  //     );
  //     setPagination(calculateRange(res.data, PAGE_SIZE));
  //   } catch (error) {
  //     setIsError(error.response);
  //   }
  // };

  const handleEdit = (data) => {
    setEditData(data);
    setEditOpen(true);
    // Set the data of the selected row
  };

  const updateUser = async (id, updatedData) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateuser/${id}`,
        updatedData,
        {
          headers: { Authorization: token },
        }
      );

      console.log(res.data); // Log the response or handle it as required.
      // Optionally, you can refetch the subcategory data after successful update.
      // getMySubCategoryData();
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: " Category Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyUserData();
    } catch (error) {
      console.error(error.response); // Handle error responses
    }
  };

  const handleCheck = async (e, id) => {
    console.log(e.target.checked, id);
    setCheckedUsers(...checkedUsers, id);
  };
  // const [selectedUserIds, setCheckedUserIds] = useState([]);

  // const handleCheckboxChange = (e, userId) => {
  //   var chekedNew = e.target.checked;
  //   let data = []
    
  //   console.log("----------------", chekedNew);
  //   console.log("users",users)
  //   setUsers((prevUsers) =>
  //     prevUsers.map((user) =>
  //       user._id === userId ? { ...user, isChecked: !user.isChecked } : user 
  //     )
  //   );

  //   // if  (chekedNew) {
  //   //   setCheckedUserIds(...selectedUserIds, userId);
  //   // }
  //   // if (!chekedNew) {
  //   //   setCheckedUserIds(selectedUserIds.filter((item) => item !== userId));
  //   // }

  //   //   setCheckedUserIds((prevCheckedUserIds) =>
  //   //   prevCheckedUserIds.includes(userId)
  //   //     ? prevCheckedUserIds.filter((id) => id !== userId)
  //   //     : [...prevCheckedUserIds, userId]
  //   // );

  //   console.log(selectedUserIds);
  // };
// checkbox butoon
    // const handleCheckboxChange = (e, user) => {
    //   const isChecked = e.target.checked;
    //   if (isChecked) {
    //     setSelectedUsers((prev) => [...prev, user]);
    //   } else {
    //     setSelectedUsers((prev) => prev.filter((u) => u._id !== user._id));
    //   }
    // };

    const handleCheckboxChange = (e, user) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        setSelectedUsers((prev) => [...prev, user]);
      } else {
        setSelectedUsers((prev) => prev.filter((u) => u._id !== user._id));
      }
    };
    // const handleCheckboxChange = (e, user,item) => {
    //   const isChecked = e.target.checked;
    //   if (isChecked) {
    //     setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, item.id,user]);
    //   } else {
    //     setSelectedUsers((prevSelectedUsers) =>
    //       prevSelectedUsers.filter((userId) => userId !== item.id)
    //     );
    //   }
    // };
    
  const handleToggle = async (e, id) => {
    const cheked = e.target.checked;
    Swal.fire({
      title: "Are you sure Want to DeActivate this Account?",
      //   text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, DeActivate it!",
    }).then(async (result) => {
      console.log(result, "result");
      if (result.isConfirmed) {
        console.log(result.isConfirmed, "result.isConfirmed");
        try {
          const token = localStorage.getItem("token");
          const res = await axios.put(
            `${process.env.REACT_APP_BASE_URL}/api/updateuser/${id}`,
            { isActive: cheked },
            {
              headers: { Authorization: token },
            }
          );
          Swal.fire("DeActivate!", "User has been deleted.", "success");
          getMyUserData();
        } catch (error) {
          console.error(error.response); // Handle error responses
        }
      }
    });
  };

  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteuser/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "user has been deleted.", "success");
          getMyUserData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Category.", "error");
        }
      }
    });
  };

  const EditSchema = Yup.object().shape({
    fullname: Yup.string().required("fullname name is Required"),
    phone: Yup.string().required("phone is Required"),
    email: Yup.string().required("email is Required"),
    date_of_birth: Yup.string().required("date_of_birth is Required"),
    exams: Yup.array()
      .of(Yup.string().required("Exam is Required"))
      .required("Exams is Required"),
  });

  const ExpirationSchema = Yup.object().shape({
    day: Yup.string().required("Day is Required"),
  });

  useEffect(() => {
    // getMyUserData();
    getMyUserData("", 1);
    // getMyUserData1();
    // getAllMyUserData();
  }, []);

  const openExpirationModal = () => {
    setExpirationModal(true);
  };
  const updateExpiration = async (data) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Order/AddexpirationDays`,
        data,
        {
          headers: { Authorization: token },
        }
      );

      setExpirationModal(false);
      console.log(res.data); // Log the response or handle it as required.
      // Optionally, you can refresh the subcategory data after successful deletion.
      // getMySubCategoryData();
      // Show a success message using SweetAlert
      Swal.fire("Done", "Expiration date has been extended.", "success");
      getMyUserData();
    } catch (error) {
      console.error(error.response); // Handle error responses
      // Show an error message using SweetAlert
      Swal.fire("Error", "Failed to delete the Category.", "error");
    }
  };

// PaginationButton
const handleChangedata = (e, page) => {
  console.log(page, "***********************************");
  setCurrentPageNumber(page);
  // setSelectedUsers([]); // Reset selectedUsers array
  getMyUserData("", page);
};
// const handleChangedata = (e, page) => {
//   setCurrentPageNumber(page);
//   setSelectedUsers("");
//   getMyUserData("", page);
// };
    // console.log("🚀 ~ handleChangedata ~ setSelectedUsers############:", setSelectedUsers)
  // const handleSearchChange = (event) => {
  //   // Ensure event.target.value is a string before calling toLowerCase
  //   const searchValue = typeof event.target.value === 'string' ? event.target.value.toLowerCase() : '';

  //   // Filter the original data based on the search query
  //   const newData = data.filter(row =>
  //     row.fullname.toLowerCase().includes(searchValue)
  //   );

  //   // Update the state with the filtered data
  //   setSearchQuery(newData);
  // };

  const handleDropdownChange = (value) => {
    getMyUserData("", currentPageNumber, value);
    console.log(value, "666666");
    setCategory(value);
  };

  const handleFilter = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    getMyUserData(query);

    const filteredData = jsonData.data?.filter((row) =>
      row.fullname.toLowerCase().includes(query)
    );
    setRecords(filteredData);
  };

  // const handlePageChange = (newPage) => {
  //   setCurrentPageNumber(newPage);
  //   setSelectedUsers([]); // Clear selected users when the page changes
  // };
  const handlePageChange = (event, page) => {
    setCurrentPageNumber(page);
    // setSelectedUsers([]); // Clear selected users
    handleChangedata(page);
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleDownloadList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user/v2/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      const data = response.data;
      
      // Filter data to include only the required fields
      // const filteredData = data.map((item) => ({
      //   Name: item.fullname,
      //   phone: item.phone,
      //   email: item.email,
      //   date_of_birth: item.date_of_birth,
      //   preparing_for: item.preparing_for,
      //   isSubscribed: item.isSubscribed,
      //   planeNames: item.planeNames,
      //   current_data: item.current_data,
      //   isActive: item.isActive,
      //   state: item.state,
      //   _id: item._id,
      // }));
      const filteredData = data.map((item) => {
        // Split 'planeNames' into individual plans
        const plans = item.planeNames ? item.planeNames.split(",").map((plan) => plan.trim()) : [];
        
        // Dynamically create plan columns
        const planColumns = {};
        plans.forEach((plan, index) => {
          planColumns[`plan${index + 1}`] = plan || "";
        });
  
        return {
          Name: item.fullname,
          phone: item.phone,
          email: item.email,
          date_of_birth: item.date_of_birth,
          preparing_for: item.preparing_for,
          isSubscribed: item.isSubscribed,
          current_data: item.current_data,
          isActive: item.isActive,
          state: item.state,
          // _id: item._id,
          ...planColumns,
        };
      });

      // Create a worksheet and workbook for the Excel file
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "UserList");

      // Save the Excel file with a dynamic name based on examId
      XLSX.writeFile(wb, `User.xlsx`);
    } catch (error) {
      console.error("Error downloading User list:", error);
    } finally {
      setIsLoading(false); // Stop loading once download is complete
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Users</h5>

        {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <button className="btn btn-primary" onClick={onOpenModal}>
            Add
            <FontAwesomeIcon icon={faPlus} className="mx-2" />
          </button>
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2>Add Category</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              getMyPostData(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>Category Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your first name"
                        name="category_name"
                        value={values.category_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.category_name &&
                        touched.category_name &&
                        errors.category_name}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>
                </Row>
               

                <div className="mt-3 flex">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal> */}

        {/* <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Category Name</th>
              <th scope="col">description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {categoryData
              .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
              .map((item, index) => (
                <tr>
                  <th scope="row">{item.recordNo}</th>
                  <td>{item.category_name}</td>
                  <td>{item.description}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="mx-3"
                      onClick={() => handleEdit(item)}
                    />

                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => handleDelete(item._id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table> */}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div></div>
          <div>
            <Button
              onClick={openExpirationModal}
              variant="primary"
              style={{ backgroundColor: "#2042d0", marginRight: "1rem" }}
            >
              Update Expiration
              <FontAwesomeIcon icon={faCalendarWeek} className="mx-2" />
            </Button>
          
            {/* <DownloadExcelButton jsonData={ selectedUsers.length === 0 ?  jsonData : selectedUsers}columnOrder={columnOrder} fileName="User" /> */}
            <Button
      onClick={handleDownloadList}
      variant="primary"
      style={{ backgroundColor: "#2042d0" }}
      disabled={isLoading} // Disable button when loading
    >
      {isLoading ? (
        <>Downloading... <FontAwesomeIcon icon={faDownload} className="mx-2" /></>
      ) : (
        <>Download Excel <FontAwesomeIcon icon={faDownload} className="mx-2" /></>
      )}
    </Button>
            
            {/* <DownloadExcelButton jsonData={selectedUsers ?? jsonData} fileName="User" /> */}

            {/* <DownloadExcelButton jsonData={yourDataArray} fileName="myExcelFile" /> */}
          </div>
        </div>
        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <h2>Edit User</h2>
          <Formik
            initialValues={editData}
            validationSchema={EditSchema}
            onSubmit={(values) => {
              updateUser(editData._id, values); // Pass the ID and updated data to updateUser
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your fullname"
                        name="fullname"
                        value={values.fullname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.fullname && touched.fullname && errors.fullname}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone Number"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.phone && touched.phone && errors.phone}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.email && touched.email && errors.email}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter date of birth"
                        name="date_of_birth"
                        value={values.date_of_birth}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.date_of_birth &&
                        touched.date_of_birth &&
                        errors.date_of_birth}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Label>Prepairing for</Form.Label>
                    <Select
                      name="exams"
                      value={values.exams.map((id) => ({
                        value: id,
                        label: id,
                      }))}
                      options={options.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                      isMulti
                      onChange={(selectedOptions) => {
                        console.log(selectedOptions, "selectedOptions");
                        setFieldValue(
                          "exams",
                          selectedOptions.map((option) => option.value)
                        );
                      }}
                    />
                    <div className="mb-3 text-danger">
                      {errors.exams && touched.exams && errors.exams}
                    </div>
                  </Col>
                </Row>

                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal
          open={expirationModal}
          onClose={() => {
            setExpirationModal(false);
          }}
          center
        >
          <h2>Add Expiration Days</h2>
          <Formik
            initialValues={expirationData}
            validationSchema={ExpirationSchema}
            onSubmit={updateExpiration}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form>
                <Row>
                  {console.log("errors ----- ", errors)}
                  <Col md={12} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Days</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Days"
                        name="day"
                        value={values.day}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div className="mb-3 text-danger">
                      {errors.day && touched.day && errors.day}
                    </div>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <h6 style={{ margin: "10px" }}>show entry</h6>
        <select
          name="category"
          value={category}
          onChange={(event) => handleDropdownChange(event.target.value)}
          style={{ width: "140px", height: "36px", marginTop: "0px" }}
        >
          {/* <option value="10">All</option> */}
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          
        </select>

        {/* Search box */}

        <div style={{ marginTop: "-44px", textAlign: "right" }}>
          <TextField
            label="Search by Name"
            variant="outlined"
            value={searchQuery}
            onChange={handleFilter}
            size="small"
            sx={{ marginBottom: "6rem" }}
          />
        </div>
        {/* <div className="text-end" input type="text" onChange={handleFilter}></div> */}
        <MDBDataTable
        striped
        bordered
        small
        data={datatable}
        paging={false}
        searching={false}
        style={{ marginTop: "-50px" }}
      />
        <div style={{ marginTop: "50px" }}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Pagination
            count={totalpage}
            variant="outlined"
            shape="rounded"
            onChange={handleChangedata}
            page={currentPageNumber}
            onPageChange={handlePageChange}
            // onChange={(e, page) => handleChangedata(page)}
            // page={currentPageNumber}
          />
        </Stack>
      </div>
      </Card.Body>
    </Card>
  );
};
